* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none;
}

/* Hide default scrollbar */
::-webkit-scrollbar {
  width: 0;
}

/* Scroll Path */
#scrollPath {
  position: fixed;
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  background: rgba(255, 255, 255, 0.05);
}

/*TOAST ALERT START*/
#toast-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.toast {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 0.9;
  transition: opacity 0.3s ease-in-out;
}
/*TOAST ALERT END*/

/***MODAL START***/
.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

/*.modal-content {
  background-color:rgb(255, 45, 108);
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  text-align: center;
}*/

.modal-content {
  /*background-color: rgba(200, 59, 59, 0.3); /* Polu-prozirna bijela boja */
  backdrop-filter: blur(10px); /* Blur efekt */
  -webkit-backdrop-filter: blur(10px); /* Za podršku u Webkit preglednicima */
  margin: 15% auto;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2); /* Polu-prozirna bordura */
  border-radius: 10px; /* Zaobljeni rubovi (opcionalno) */
  width: 50%;
  text-align: center;
}

.confirm-btn,
.cancel-btn {
  background: transparent;
  margin: 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 10px;
}

.confirm-btn,
.cancel-btn:hover {
  background: linear-gradient(to right, #1877f2, #3b5998);
}

.confirm-btn,
.cancel-btn:active {
  transform: scale(0.98);
}
/***MODAL END***/

/***MODAL2 START***/
.modal_2 {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(255, 0, 68, 0.43);
}

.modal-content_2 {
  /*background-color: rgba(200, 59, 59, 0.3); /* Polu-prozirna bijela boja */
  backdrop-filter: blur(10px); /* Blur efekt */
  -webkit-backdrop-filter: blur(10px); /* Za podršku u Webkit preglednicima */
  margin: 15% auto;
  padding: 20px;
  border: 2px solid rgba(0, 0, 0, 0.2); /* Polu-prozirna bordura */
  border-radius: 10px; /* Zaobljeni rubovi (opcionalno) */
  width: 25%;
  text-align: center;
  font-size: 12px;
  weight: 600;
}

.modal-message_2 {
  font-size: 16px;
  font-weight: 600;
}

.cancel-btn_2 {
  background: transparent;
  margin: 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
}

.cancel-btn_2:hover {
  background: linear-gradient(to right, #1877f2, #3b5998);
  transition: transform 0.5s ease;
}

.cancel-btn_2:active {
  transform: scale(0.98);
}
/***MODAL2 END***/

/* PROGRESS BAR START */
#progressbar {
  position: fixed;
  top: 0;
  right: 0;
  width: 10px;
  background: linear-gradient(to top, #008aff, #00ffe7);
  animation: animate 5s linear infinite;
}

@keyframes animate {
  0%,
  100% {
    filter: hue-rotate(0deg);
  }
  50% {
    filter: hue-rotate(360deg);
  }
}

#progressbar:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #008aff, #00ffe7);
  filter: blur(10px);
}

#progressbar:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #008aff, #00ffe7);
  filter: blur(30px);
}
/*******PROGRESS BAR END*********/

/*****HEADER*****/

#timer {
  font-size: 2rem;
  margin-bottom: 20px;
}

.header {
  position: fixed;
  height: 80px;
  width: 100%;
  background-color: #0f101e; /*#FFD700*/
  z-index: 100;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav {
  width: 100%;
  max-width: 1600px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav_left {
  display: flex; /* Postavljanje elemenata u red */
  align-items: center; /* Vertikalno centriranje elemenata */
  justify-content: space-between; /* Razmak između logotipa i teksta */
}

.nav_logo {
  font-size: 35px;
  font-weight: 800;
  -webkit-text-stroke: 1px black; /* Okvir teksta */
  text-stroke: 3px black;
  display: flex;
}

.nav_logo span {
  display: inline-block; /* Prikaz elemenata u istom redu */
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: transform 0.6s ease-in-out, -webkit-text-fill-color 0.3s;
  transition: transform 0.6s ease-in-out, text-fill-color 0.3s;
  transition: transform 0.6s ease-in-out, text-fill-color 0.3s,
    -webkit-text-fill-color 0.3s;
}

.nav_logo span:hover {
  transform: rotate(360deg); /* Rotacija pri hoveru */
  -webkit-text-fill-color: transparent; /* Promena boje pri hoveru */
}

.logo-box {
  display: flex; /* Fleksibilni kontejner za sliku */
  align-items: center; /* Vertikalno centriranje slike */
  justify-content: center; /* Horizontalno centriranje */
  width: 60px; /* Širina logo-box */
  height: 50px; /* Visina logo-box */
  margin-left: 10px; /* Razmak između logo-box i teksta */
  transition: transform 0.5s ease;
}

.logo-box img {
  max-width: 100%; /* Logo se prilagođava veličini boxa */
  height: auto; /* Očuvanje omjera stranica */
  display: block; /* Uklanja razmake oko slike */
  height: 50px;
  width: auto;
  transition: transform 0.6s ease-in-out, -webkit-text-fill-color 0.3s;
}

.logo-box img:hover {
  transform: rotate(360deg); /* Rotacija pri hoveru */
}

/* Definicija animacije rotacije */
@keyframes rotate {
  from {
    transform: rotate(0deg); /* Početni kut rotacije */
  }
  to {
    transform: rotate(360deg); /* Krajnji kut rotacije */
  }
}

.nav_center {
  flex: 2;
  display: flex;
  justify-content: center-start;
  align-items: center;
  margin-left: 200px;
}

.nav_items {
  display: flex;
  gap: 20px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}

.nav_item {
  column-gap: 25px;
}

.nav_link {
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  font-size: 24px;
}

.nav_link:hover {
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
}

.nav_right {
  background: transparent;
  z-index: 1;
}

.nav_right .button:hover {
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
}

.button_login,
.button_logout {
  padding: 2px 18px;
  border: 1px solid #ff0f7b;
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-radius: 15px;
  cursor: pointer;
  width: 100px;
  height: 30px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.button_login:hover,
.button_logout:hover {
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.button_login:active,
.button_logout:active {
  transform: scale(0.98);
}

.button_loginnow,
.button_signup,
.button_reset,
.button_setpass {
  margin-top: 10px;
  padding: 0px 0px;
  border: 1px solid white;
  border-radius: 15px;
  cursor: pointer;
  width: 180px;
  height: 25px;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.button_loginnow:hover,
.button_signup:hover,
.button_reset:hover,
.button_setpass:hover {
  background: transparent;
}

.button_loginnow:active,
.button_signup:active,
.button_reset:active,
.button_setpass:active {
  transform: scale(0.98);
}

/*
.button {
  padding: 2px 18px;
  border: 1px solid white;
  border-radius: 15px;
  cursor: pointer;
  width: 100px;
  height: 30px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.button:hover {
  background: transparent;
}

.button:active {
  transform: scale(0.98);
}
*/

/* Hamburger menu styles */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  /*background-color: white;*/
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-color: text;
  -webkit-text-fill-color: transparent;
  margin: 4px 0;
  transition: 0.4s;
}

.mobile_menu {
  display: none;
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  background-color: #0f101e;
  padding: 10px 0;
}

.mobile_menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mobile_menu ul li {
  text-align: center;
  padding: 10px 0;
}

/*Home*/
.home {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  /*background-color: #7F00FF;*/
  background-size: cover;
  background-position: center;
  justify-content: center;
  display: flex;
  align-items: center;
}
/* Banner Section Styles */
/* Banner Section Styles */
.banner-section {
  position: relative;
  max-width: 100%;
  height: 80px; /* Adjust height as needed */
  overflow: hidden;
}

.banner-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.banner-slider {
  display: flex;
  width: 100%; /* To accommodate both the original and duplicated banners */
  height: 100%;
  animation: slider 15s infinite linear; /* Adjust animation duration as needed */
  transition: transform 1s ease;
  transform: translateX(0);
}

.banner {
  height: 100%;
  /* min-width: 25%; */
  flex: 0 0 25%;
}

.banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.banner a {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

/* Keyframes for Sliding Animation */
@keyframes slider {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0); /* Move by the width of one set of banners */
  }
}

/* Hover Effect */
.banner-container:hover .banner-slider {
  animation-play-state: paused !important; /* Pause animation when hovering */
}

.banner-container:hover .banner {
  filter: blur(5px); /* Apply blur to all banners */
}

.banner-container:hover .banner:hover {
  filter: none; /* Remove blur for the hovered banner */
  transform: scale(1.05); /* Slightly scale up the hovered banner */
  z-index: 1; /* Bring the hovered banner to the front */
}

/* Banner Section Styles */

/* Ensure home section starts below the banner section */
.home {
  margin-top: 0px; /* Add margin-top to separate from banner section */
}

.background-picture {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover; /*Cover the entire container, maintaining aspect ratio */
  transform: translate(-50%, -50%);
  z-index: -1; /*Place it behind other content }*/
}

.home::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease-out;
}
.home.show ::before {
  opacity: 1;
  pointer-events: auto;
}

.form_container {
  position: absolute;
  max-width: 320px;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.2);
  z-index: 101;
  background: rgba(50, 50, 50, 0.01);
  backdrop-filter: blur(10px);
  border: 2px solid #000;
  padding: 25px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease-out;
}

.home.show .form_container {
  opacity: 1;
  pointer-events: auto;
  transform: translate(-50%, -50%) scale(1);
}

.form_container .form {
  display: none;
}

.form_container .form.active {
  display: block;
}

.signup_form {
  display: none;
}

.form_container.active .signup_form {
  display: block;
}

.form_container.active .login_form {
  display: none;
}

.form_close {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #0b0217;
  font-size: 22px;
  opacity: 0.7;
  cursor: pointer;
  z-index: 102; /* veći od z-indexa form_container */
}

.form_container h2 {
  font-size: 22px;
  color: #0b0217;
  text-align: center;
}

.input_box {
  margin-top: 30px;
  position: relative;
  width: 100%;
  height: 40px;
  background: rgba(50, 50, 50, 0.01);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 12px;
}

.input_box input {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  padding: 0 30px;
  color: #e5b80b;
  transition: all 0.2s ease;
  border-bottom: 1.5px solid #aaaaaa;
  background: rgba(50, 50, 50, 0.01);
  backdrop-filter: blur(10px);
  /*border: none;*/
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: border 0.3s ease-in-out;
  border-radius: 12px;
}

.input_box input:focus {
  border-color: #e5b80b;
}

.input_box i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
}

.input_box i.email,
.input_box i.password {
  left: 0;
}

.input_box input:focus ~ i.email,
.input_box input:focus ~ i.password {
  color: #e5b80b;
}

.input_box i.pw-hide {
  right: 0;
  font-size: 18px;
  cursor: pointer;
}

/* Efekt tresenja */
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

/* Crveni border kada je unos neispravan */
.input_box.error input {
  border: 2px solid red;
  animation: shake 0.3s ease-in-out;
}

.option_field {
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form_container a {
  color: black;
  font-size: 12px;
}

.form_container a:hover {
  text-decoration: underline;
}

.checkbox {
  display: flex;
  column-gap: 8px;
  white-space: nowrap;
}

.checkbox input {
  accent-color: #7d2ae8;
}

.checkbox label {
  font-size: 12px;
  user-select: none;
  color: #0b0217;
  cursor: pointer;
}

.form_container .button_loginnow,
.button_signup,
.button_reset,
.button_setpass {
  background: transparent;
  margin-top: 30px;
  width: 100%;
  padding: 0px 0px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.form_container .button_loginnow:hover,
.button_signup:hover,
.button_reset:hover,
.button_setpass:hover {
  background: linear-gradient(to right, #1877f2, #3b5998);
}

.login_signup {
  font-size: 12px;
  text-align: center;
  margin-top: 15px;
}

.forgot_password_form {
  display: none;
}

.form_container.active-forgot .forgot_password_form {
  display: block;
}

.forgot_password_form .button {
  background: #f44336; /* Crvena boja */
}

.reset_password_form {
  display: none;
}

.form_container.active-reset .reset_password_form {
  display: block;
}

/************walllet******/

#connectWallet {
  display: none; /* Ensuring it's hidden initially */
  /* other styles */
}

/* Styles for the connect wallet button */
.connectWalletBtn {
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Facebook plavi gradijent */
  color: black;
  padding: 2px 18px; /* Dodano px za padding */
  border: 1px solid #ff0f7b;
  border-radius: 15px;
  cursor: pointer;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  line-height: 1; /* Resetiraj line-height */
  width: 200px;
  height: 30px;
  font-weight: 600;
  margin-right: 10px;
}

.connectWalletBtn:hover {
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Obrnuti Facebook plavi gradijent na hover */
}

/************counter***********/

.counter-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #0f101e;
  border: 1px solid #f89b29;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 600px;
  font-weight: 600;
  height: auto; /* Postavite visinu na auto kako bi se prilagodila sadržaju */
}

#crypto-counter-head span p {
  font-weight: 800;
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*#crypto-address {
  font-size: 16px;
  position: relative; 
  width: 100%;
  text-align: center;
  white-space: nowrap;
  z-index: 1003;
  display: inline-block;
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
}*/

#crypto-address {
  font-size: 16px;
  position: relative;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
}

#crypto-address span {
  display: inline-block;
  color: black;
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  z-index: 1003;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

#crypto-address.fade-in span {
  opacity: 1; /* Prikazivanje teksta */
}

#crypto-address.fade-out span {
  opacity: 0; /* Skrivanje teksta */
}

.counter-item {
  margin: 10px 0;
  font-size: 1.5rem;
  border: 1px solid #f89b29;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.button-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.ticket-button {
  padding: 10px 20px;
  font-size: 1rem;
  margin: 5px;
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: black;
  cursor: pointer;
  border: 1px solid #f89b29;
  border-radius: 5px;
  transition: background-color 0.3s;
  font-weight: 800;
  border-color: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
}

.ticket-button:hover {
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.disabled-button {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  pointer-events: none;
}

.progress-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 15px;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 0px;
}

.time-progressbar {
  height: 10px;
  width: 100%;
  background-color: #76c7c0;
  transition: width 1s linear;
  justify-content: center;
  align-items: center;
  display: flex;
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
}

/* Footer */
.footer {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 20px !important;
  background-color: #0f101e !important;
  color: black !important;
  font-size: 14px !important;
  border-top: 1px solid #444 !important;
  flex-wrap: wrap;
}

.footer_left p {
  margin: 0 !important;
  font-weight: 600;
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer_left p:hover {
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer_center {
  display: flex !important;
  gap: 15px !important;
}

.footer_center a {
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 30px !important;
  /*transition: color 0.5 ease!important;*/
  display: inline-block;
  transition: transform 0.5s ease-in-out;
}

.footer_center a:hover {
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rotateIcon 1s ease-in-out forwards;
}

@keyframes rotateIcon {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.footer_right {
  display: flex !important;
  gap: 15px !important;
  font-weight: 600;
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer_right a {
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none !important;
  transition: color 0.3s !important;
}

.footer_right a:hover {
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*******ABOUT SECTION*******/

.about {
  text-align: center; /* Centriranje sekcije */
  padding: 20px; /* Unutarnji razmak za cijelu sekciju */
  background-color: #0f101e; /* Pozadinska boja */
}

.about-table {
  margin: 0 auto; /* Centriranje sadržaja */
  max-width: 1600px; /* Maksimalna širina sadržaja */
  padding: 20px; /* Unutarnji razmak */
}

.about-table h2 {
  font-size: 2em;
  margin-bottom: 20px;
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center; /* Centriranje teksta */
}

.aboutp {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: justify; /* Poravnanje teksta s lijeve i desne strane */
  line-height: 1.8; /* Povećanje razmaka između redaka za bolju čitljivost */
}

/*******CONTACT SECTION*******/

.contact_section {
  text-align: center;
  padding: 20px;
  background-color: #0f101e;
}

.contact_section h2 {
  font-size: 2em;
  margin-bottom: 10px;
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact_section p {
  font-size: 1.2em;
  margin-bottom: 20px;
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.qr_code {
  width: 200px;
  height: 200px;
  border: none;
  padding: 10px;
  background-color: transparent;
  transition: transform 1.5s ease-in-out; /* Glatka tranzicija */
  transform-style: preserve-3d; /* Očuvanje 3D transformacija */
}

.qr_code:hover {
  transform: rotateY(360deg); /* Rotacija za 360 stupnjeva pri hoveru */
}

/*******WINNER SEKCIJA********/

/* Stilovi za winner sekciju */
.winners {
  padding: 50px 0;
  background-color: #0f101e !important;
  text-align: center;
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
  justify-content: center;
  display: flex;
  align-items: center;
  z-index: -1;
  min-height: 50vh; /* Minimalna visina, ali omogućava rast */
  height: auto; /* Omogućuje dinamičko povećanje visine */
}

.background-video-winners {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the entire container, maintaining aspect ratio */
  transform: translate(-50%, -50%); /* Center the video */
  z-index: -1; /* Place it behind other content */
}

.winners.show {
  display: block;
  opacity: 1;
}

.winner-table {
  max-width: 1200px;
  margin: 0 auto;
}

.winner-table h2 {
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  margin-bottom: 20px;
}

.winner-table table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #f89b29;
  /*background-color: #fff;*/
  width: 100%;
}

.winner-table th,
.winner-table td {
  background: linear-gradient(120deg, #ff0f7b 0%, #f89b29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 10px;
  border: 1px solid #f89b29;
  width: 1200px;
}

.winner-table th {
  /*background-color: #f2f2f2;*/
  font-weight: bold;
}

.winner-table tbody tr:nth-child(even) {
  /*background-color: #f9f9f9;*/
}

.winner-table tbody tr:hover {
  /*background-color: #f0f0f0;*/
}

/***********responsive********/

/* Responsive design */
@media (max-width: 1600px) {
  .logo-box img {
    margin-right: 0;
  }
}

@media (max-width: 1024px) {
  .nav_logo {
    font-size: 28px;
  }

  .nav_link {
    font-size: 20px;
  }

  .button {
    width: 90px;
    height: 28px;
    font-size: 14px;
  }
  /* Dodaj ovu liniju */
  .logo-box img {
    margin-right: 0;
  }
}

@media (max-width: 880px) {
  .nav_items,
  .nav_right {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  .nav_logo {
    font-size: 24px;
  }

  /* Dodaj ovu liniju */
  .logo-box img {
    margin-right: 0;
  }
  .nav_link {
    font-size: 18px;
  }

  .button {
    width: 80px;
    height: 26px;
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  /* Osnovni stilovi za counter-box */
  .counter-box {
    margin: 10px;
    padding: 20px;
    border-radius: 8px; /* Zaobljeni kutovi */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Suptilna sjena */
  }

  .counter-item {
    margin-bottom: 10px;
    text-align: center; /* Centriraj tekst */
  }

  #crypto-counter-head p {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }

  .button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Centriraj gumbe */
    gap: 10px; /* Razmak između gumba */
  }

  .ticket-button {
    padding: 10px 15px; /* Veći gumbi za lakše klikanje */
    font-size: 14px;
    border: none;
    border-radius: 5px;
    background-color: #007bff; /* Plava boja za gumbe */
    color: white;
    cursor: pointer;
  }

  .ticket-button:hover {
    background-color: #0056b3; /* Tamnija plava boja pri prelazu miša */
  }

  .progress-container {
    margin-top: 20px;
    text-align: center; /* Centriraj progress bar */
  }

  .time-progressbar {
    width: 100%;
    height: 10px;
    background-color: #007bff; /* Plava boja progress bar-a */
    border-radius: 5px;
  }
}
/* Stilovi za ekran širine do 500px */
@media (max-width: 500px) {
  .counter-box {
    padding: 10px;
  }

  #crypto-counter-head p {
    font-size: 16px;
  }

  .ticket-button {
    padding: 8px 12px;
    font-size: 12px;
  }

  .time-progressbar {
    height: 8px;
  }

  .button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Raspored dugmića */
    gap: 3px;
  }

  .button-container button {
    flex: 1;
    margin: 5px;
    font-size: 11px;
    height: 36px;
    width: auto; /* Dugmići će zauzimati prostor prema sadržaju */
    border: 1px solid #f89b29;
    text-align: center;
    padding: 5px;
  }

  .about {
    max-width: 100%;
    padding: 10px;
  }

  .about-table {
    max-width: 100%;
    padding: 10px;
    margin-left: -5px;
  }

  .about-table h2 {
    font-size: 1.3rem;
    text-align: center;
  }

  .about-table p {
    font-size: 1rem; /* Blago povećana veličina fonta */
    line-height: 1.6; /* Povećan line-height za bolju čitljivost */
    text-align: justify;
    margin: 10px 0;
    hyphens: auto; /* Dodaje prijelom reči na manjim ekranima */
  }

  .banner-section {
    position: relative;
    max-width: 100%;
    height: 50px;
    overflow: hidden;
  }

  .banner-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .banner-slider {
    display: flex;
    width: 100%;
    height: 100%;
    animation: slider 15s infinite linear;
    transition: transform 1s ease;
    transform: translateX(0);
  }

  .banner {
    height: 100%;
    flex: 0 0 50%;
  }

  .banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  .banner a {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  .nav {
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Validna vrednost za razmak */
    margin: 0;
    padding: 0 10px;
  }

  .nav_left {
    flex: 1;
    display: block;
    align-items: center;
    /*justify-content: flex-start;*/
    justify-content: space-between; /* Validna vrednost za razmak */
    margin-right: 0px;
    max-width: 140px;
  }

  .logo-box {
    display: flex; /* Flexbox za poravnanje sadržaja */
    align-items: center; /* Vertikalno centriranje */
    justify-content: center; /* Horizontalno centriranje */
    width: 100px; /* Širina logo-box */
    height: 50px; /* Visina logo-box */
    margin: 0 auto; /* Centriranje logo-box unutar roditelja */
  }

  .logo-box img {
    max-width: 100%; /* Ograničenje širine slike na veličinu roditelja */
    max-height: 100%; /* Ograničenje visine slike na veličinu roditelja */
  }

  .header {
    position: fixed;
    height: 60px;
    width: 100%;
    background-color: #0f101e;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav_logo {
    font-size: 30px;
    font-weight: 800;
    display: flex;
    width: auto;
  }

  .nav_logo span {
    font-size: 16px;
    transition: transform 0.6s ease-in-out;
  }

  .nav_center {
    display: none; /* Skriveno na manjim ekranima */
  }
}

/*IPHONE 16PRO*/

/* Responsive @media za uređaje do 430px */
@media (max-width: 430px) {
  .nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    overflow: hidden;
  }

  .nav_left {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-shrink: 1;
  }

  .nav_logo {
    font-size: 16px;
    white-space: nowrap;
  }

  .logo-box {
    display: flex;
    align-items: center;
  }

  .logo-box img {
    height: 24px;
    max-width: 50px;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    margin: 0 10px;
    position: relative;
    z-index: 101;
  }

  .mobile_menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    flex-direction: column;
    background-color: #0f101e;
    padding: 10px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 100;
  }

  .mobile_menu.active {
    display: flex;
  }

  .mobile_menu a {
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    color: #333;
  }

  .winners {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
  }

  .winner-table {
    width: 100%;
    overflow-x: auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }

  th, td {
    padding: 8px 12px;
    text-align: left;
    border: 1px solid #ddd;
    word-wrap: break-word;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th {
    font-size: 12px;
  }

  td {
    font-size: 12px;
  }

  th:nth-child(1), td:nth-child(1) {
    max-width: 50px;
  }

  th:nth-child(3), td:nth-child(3) {
    max-width: 80px;
  }

  th:nth-child(2), td:nth-child(2),
  th:nth-child(4), td:nth-child(4) {
    max-width: 100px;
  }

  .wallet_connect_hidden {
    display: none;
  }


  .hidden {
    display: none;
  }

  .hidden_no{
    display: block;
  }

  #contact_link + #loginm_link {
    margin-top: 5px;
    margin-bottom: 0; /* Dodano za uklanjanje donjeg razmaka */
  }

  /* Dodano za uklanjanje margina između elemenata */
  #contact_link,
  #loginm_link {
    margin: 0;
    padding: 0;
  }
}



/* Show mobile menu when hamburger is active */
.hamburger.active + .mobile_menu {
  display: block;
}
